import { element } from "prop-types"
import React from "react"
import "./layout.css"
// 画面スクロールしたときにGoogleMapの拡大縮小の動作が反応してしまうため、
// クリックしたときにスクロールで拡大縮小がアクティブになるように設定
const MapClick = () => {
  document.getElementById("googlemap").style.pointerEvents = "auto"
}

const SiteMap = () => {
  return (
    <div className="map-content syouei-map-mb" onClick={MapClick}>
      <iframe
        id="googlemap"
        className="googlemap"
        src="https://www.google.com/maps/d/u/4/embed?mid=1T8LkHpNuVW8A8aMRKqTLBqTCChN_tJEy"
      >
        株式会社ショウエイ
      </iframe>
    </div>
  )
}
export default SiteMap
