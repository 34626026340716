import * as React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./layout.css"
import { Link } from "gatsby"

import sp_kv0 from "../images/sp-kv-0.png"
import sp_kv1 from "../images/sp-kv-1.png"
import sp_kv2 from "../images/sp-kv-2.png"
import arrow from "../images/arrow.png"

const spSlide = () => {
  // slideの設定
  const settings = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0px",
    width: "auto",
  }
  return (
    <Slider {...settings} className="d-block d-md-none">
      <div className="px-2">
        <img src={sp_kv0} />
        <div className="sp-base">
          <div>
            <h2 className="text-center  syouei-font-bold">
              配管施工ならおまかせ
            </h2>
            <p className="text-center text-red mt-2  syouei-font-bold">
              ショウエイについて
            </p>
            <p className="sp-info-text mt-3 ">
            エネルギーインフラを通じて油配管、タンク据え付け、計量器取付ガソリンスタンド、石油プラント等の工事をしています。
            </p>
          </div>
          <div className="bg-white text-center h-15 position-relative sp-info-second mt-4">
            <p className="h-100 d-flex align-items-center justify-content-center">
              <Link
                to="/aboutus"
                className="text-reset text-decoration-none d-block w-100 h-auto syouei-font-en"
              >
                ABOUT US
              </Link>
            </p>
            <img className="sp-arrow position-absolute" src={arrow} />
          </div>
        </div>
      </div>
      <div className="base px-2">
        <img src={sp_kv1} />
        <div className="sp-base">
          <div>
            <h2 className="text-center sp-en syouei-font-en syouei-font-bold">
              JOIN US
            </h2>
            <p className="text-center text-red mt-2 syouei-font-bold">
              メンバーを募集してます
            </p>
            <p className="sp-info-text mt-3 ">
            石油や配管工は水道や空調、ガスなど、人々の生活に必要不可欠なものです。人々の暮らしを影から支える縁の下の力持ちとして、一緒に働いてくれる仲間を募集しています。
            </p>
          </div>
          <div className="bg-white text-center h-15 position-relative sp-info-second mt-4">
            <p className="h-100 d-flex align-items-center justify-content-center">
              <Link
                to="careers"
                className="text-reset text-decoration-none d-block w-100 h-auto syouei-font-en"
              >
                CAREERS
              </Link>
            </p>
            <img className="sp-arrow position-absolute" src={arrow} />
          </div>
        </div>
      </div>
      <div className="base px-2">
        <img src={sp_kv2} />
        <div className="sp-base">
          <div>
            <h2 className="text-center syouei-font-bold">細部にこだわります</h2>
            <p className="text-center text-red mt-2 syouei-font-bold">
              施工情報
            </p>
            <p className="sp-info-text mt-3 ">
            当社の施工は、仕上がりの細かい見た目や機能部分にもこだわって行います。品質に自信を持って仕事をすることで、お客様の信頼を獲得して参ります。
            </p>
          </div>
          <div className="bg-white text-center h-15 position-relative sp-info-second mt-4">
            <p className="h-100 d-flex align-items-center justify-content-center">
              <Link
                to="works"
                className="text-reset text-decoration-none d-block w-100 h-auto syouei-font-en"
              >
                WORKS
              </Link>
            </p>
            <img className="sp-arrow position-absolute" src={arrow} />
          </div>
        </div>
      </div>
    </Slider>
  )
}
export default spSlide
