import * as React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./layout.css"
import { Link } from "gatsby"

import kv0 from "../images/kv-0.png"
import kv1 from "../images/kv-1.png"
import kv2 from "../images/kv-2.png"
import arrow from "../images/arrow.png"

const Slide = () => {
  // slideの設定
  const settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "15%",
    with: "auto",
    adaptiveHeight: true,
  }
  return (
    <Slider {...settings} className="container-fluid d-none d-md-block">
      <div className="base px-2">
        <img src={kv0} />
        <div className="info-parent">
          <div className="bg-white info-first h-77">
            <h2 className="syouei-font-bold">
              配管施工なら
              <br />
              おまかせ
            </h2>
            <p className="info-title position-absolute syouei-font-bold">
              ショウエイについて
            </p>
            <p className="info-text position-absolute text-left syouei-font-regular">
              エネルギーインフラを通じて油配管、タンク据え付け、計量器取付ガソリンスタンド、石油プラント等の工事をしています。
            </p>
          </div>
          <div className="bg-white info-second h-15">
            <p className="h-100 d-flex align-items-center justify-content-center text-center">
              <Link
                to="/aboutus"
                className="text-reset text-decoration-none d-block w-100 h-auto syouei-font-en"
              >
                ABOUT US
              </Link>
            </p>
            <img className="arrow position-absolute" src={arrow} />
          </div>
        </div>
      </div>
      <div className="base px-2">
        <img src={kv1} />
        <div className="info-parent">
          <div className="bg-white info-first h-77">
            <h2 className="en syouei-font-en syouei-font-bold">
              JOIN
              <br />
              US
            </h2>
            <p className="info-title position-absolute syouei-font-bold">
              メンバーを募集しています
            </p>
            <p className="info-text position-absolute text-left">
              石油や配管工は水道や空調、ガスなど、人々の生活に必要不可欠なものです。人々の暮らしを影から支える縁の下の力持ちとして、一緒に働いてくれる仲間を募集しています。
            </p>
          </div>
          <div className="bg-white info-second h-15">
            <p className="h-100 d-flex align-items-center justify-content-center text-center">
              <Link
                to="/careers"
                className="text-reset text-decoration-none d-block w-100 h-auto syouei-font-en"
              >
                CAREERS
              </Link>
            </p>
            <img className="arrow position-absolute" src={arrow} />
          </div>
        </div>
      </div>
      <div className="base px-2">
        <img src={kv2} />
        <div className="info-parent">
          <div className="bg-white info-first h-77">
            <h2 className=" syouei-font-bold">
              細部に
              <br />
              こだわります
            </h2>
            <p className="info-title position-absolute syouei-font-bold">
              施工情報
            </p>
            <p className="info-text position-absolute text-left">
              当社の施工は、仕上がりの細かい見た目や機能部分にもこだわって行います。品質に自信を持って仕事をすることで、お客様の信頼を獲得して参ります。
            </p>
          </div>
          <div className="bg-white info-second text-center h-15">
            <p className="h-100 d-flex align-items-center justify-content-center text-center">
              <Link
                to="/works"
                className="text-reset text-decoration-none d-block w-100 h-auto syouei-font-en"
              >
                WORKS
              </Link>
            </p>
            <img className="arrow position-absolute" src={arrow} />
          </div>
        </div>
      </div>
    </Slider>
  )
}
export default Slide
