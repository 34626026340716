import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Slide from "../components/carousel"
import SpSlide from "../components/sp-carousel"
import SiteMap from "../components/googlemap"
import { width } from "dom-helpers"
import { func } from "prop-types"
import PhotoMenu from "../components/photomenu"
import "../components/syouei.css"

function IndexPage() {
  return (
    <Layout>
      <Seo title="TOP" />
      {/* スマホ表示 */}
      <SpSlide />
      {/* PC表示 */}
      <Slide />
      <div className="mt-5 mt-md-0"></div>
      <div className="syouei-accessmap-nopadding">
        <div className="container">
          <div className="syouei-accessmap">
            <h2 className="syouei-font-en syouei-font-bold syouei-accessmap-title">
              ACCESS MAP
            </h2>
            <div className="syouei-map-text">
              多摩モノレール上北台駅より徒歩15分
            </div>
          </div>
        </div>
      </div>
      <SiteMap />
      <PhotoMenu />
    </Layout>
  )
}

export default IndexPage
